var debugTimes = false;

/* Polyfill closest */
(function(ELEMENT) {
  ELEMENT.matches = ELEMENT.matches || ELEMENT.mozMatchesSelector || ELEMENT.msMatchesSelector || ELEMENT.oMatchesSelector || ELEMENT.webkitMatchesSelector;
  ELEMENT.closest = ELEMENT.closest || function closest(selector) {
      if (!this) return null;
      if (this.matches(selector)) return this;
      if (!this.parentElement) {return null}
      else return this.parentElement.closest(selector)
    };
}(Element.prototype));

/**
 * Common Code
 */


$(document).ready(function () {
    $(".js-dropdown-catalog").on("click", function(){
        $(".js-catalog-header:not('active')").toggleClass("active")
        $(".js-dark-background").toggleClass("active")
        $(this).toggleClass("active")
    })
    $(document).mouseup(function (e){ 
        var button = $(".js-dropdown-catalog");
		var catalog = $(".js-catalog-header");
		var background = $(".js-dark-background"); 
        var searchWrapper = $(".js-search-wrapper"); 

		if (searchWrapper.hasClass("active") && !searchWrapper.is(e.target)  
		    && searchWrapper.has(e.target).length === 0) {
            searchWrapper.removeClass("active")
            background.removeClass("active")
        }
        
		if (catalog.hasClass("active") && !catalog.is(e.target)
		    && catalog.has(e.target).length === 0 && !button.is(e.target)) { 
            catalog.removeClass("active");
            background.removeClass("active")
            button.removeClass("active")
		}
    });
    $(".js-product").css("display", "none" )
    $(".js-sub-category").css("display", "none" )
    $(".js-product[parentSubCategory='1']").css("display", "block" )
    $(".js-sub-category[ParentMainCategory='1'").css("display", "block" )

    $(".js-main-category").hover(function(){
        $(this).siblings().removeClass("active-category")
        $(this).addClass("active-category")

        var idMainCat = $(this).attr("mainCategory")
        $(".js-sub-category").css("display", "none" )
        $(".js-sub-category[ParentMainCategory=" + idMainCat + "]").css("display", "block" )
        $(".js-product").css("display", "none" )
        $(".js-sub-category").removeClass("active-category")
    })

    $(".js-sub-category").hover(function(){
        $(this).siblings().removeClass("active-category")
        $(this).addClass("active-category")

        var idSubCat = $(this).attr("subCategory")
        $(".js-product").css("display", "none" )
        $(".js-product[parentSubCategory=" + idSubCat + "]").css("display", "block" )
    })


    $(".js-search-input").on("input", function(){
        if($(this).val() != 0){
            $(".js-search-wrapper").addClass("active")
            $(".js-dark-background").addClass("active")
        }
        else{
            $(".js-search-wrapper").removeClass("active")
            $(".js-dark-background").removeClass("active")
        }
        // CorrectionPrice2();
    })
    $(".js-search-remove").on("click", function(){
        $(".js-search-wrapper").removeClass("active")
        $(".js-dark-background").removeClass("active")
        $(".js-search-input").val("")
    })
    // function CorrectionPrice2(){
    //     $('.search-dropdown__price').each(function(){
    //         debugger;
    //         var value = +$(this).html();
    //         var newValue = value.toLocaleString("ru-RU", { minimumFractionDigits: 2 }).replace(',', '.')
    //         $(this).html(newValue)
    //     })
    // }
    

});

$(document).ready(function () {
	$('.feedback-link').magnificPopup({
		type: 'inline',
		preloader: false
	});

    $(".js-form-input-name").on("input", function () {
        var s = $(this).val();
        s = s.replace(/[^a-zA-Zа-яА-ЯёЁ\s\-]/gi, "");
        $(this).val(s);
    });

    $(".js-form-input-inn").on("input", function () {
        var s = $(this).val();
        s = s.replace(/[^0-9]/gi, ""),
            $(this).val(s);
    });


    $(".js-form-input-phone").inputmask({
        'mask': '+7 (999) 999-99-99',
        showMaskOnHover: false,
        onBeforePaste: function (pastedValue, opts) {
          var newValue = pastedValue.replace(/[^0-9]/ig, '');
          newValue = newValue.substring(newValue.length - 10);
          return newValue;
        },
      });
    
	var patternEmail = /^[a-z0-9_-]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/i;
	var button = $(".feedback__button");
	var email = $(this).find(".js-form-input-email"),
		phone = $(this).find(".js-form-input-phone"),
		inn = $(this).find(".js-form-input-inn"),
		organization = $(this).find(".js-form-input-organization"),
		name = $(this).find(".js-form-input-name"),
		error = 0;
        // 1 ошибка длины
		// 2 ошибка валидации

	var emailValid = true,
		phoneValid = false,
		innValid = false,
		organizationValid = false,
		nameValid = false;
		

        

	$(name).on("input", function(){
		if ($(this).val().length > 0) {
			error = 0;
			nameValid = true;
			ValidDataInput($(this), error)
        }
        else {
			error = 1;
			nameValid = false;
			ValidDataInput($(this), error)
        }
	})
        
	$(organization).on("input", function(){
        if (organization.val().length > 0) {
			error = 0;
			organizationValid = true;
            ValidDataInput(organization, error)
        }
        else {
			error = 1
			organizationValid = false;
            ValidDataInput(organization, error)
        }
	})


	$(phone).bind("input paste", function(){
        var value = phone.val().replace(/[^0-9]/ig, '');
        if(value.length == 11){
            error = 0;
			phoneValid = true;
            ValidDataInput(phone, error)
        }
        else if (phone.inputmask("unmaskedvalue").length == 0) {
			error = 1;
			phoneValid = false;
            ValidDataInput(phone, error)
        }
        else {
			error = 2;
			phoneValid = false;
            ValidDataInput(phone, error)
        }
	})
	$(inn).on("input", function(){
        if (inn.val().length >= 10 && inn.val().length <= 12) {
			error = 0;
			innValid = true;
            ValidDataInput(inn, error)

        }
        else if (inn.val().length == 0) {
			error = 1
			innValid = false;
            ValidDataInput(inn, error)
        }
        else {
			error = 2
			innValid = false;
            ValidDataInput(inn, error)
        }
	})

	$(email).on("input", function(){
        if (email.val() != '') {
            if(email.val().search(patternEmail) == 0){
				error = 0;
				emailValid = true;
                ValidDataInput(email, error)
            }
            else{
				error = 2
				emailValid = false;
                ValidDataInput(email, error)
            }
        }
        else if (email.val().length == 0) {
			error = 0
			emailValid = true;
            ValidDataInput(email, error)
        }
	})  

	$('.js-file-input').on('change', function (e) {
		var $fileLabel = $('.js-file-name'),
			labelVal = $fileLabel.html(),
			fileName = '';
		error = false;
		$(this).removeClass('error');

		$('.js-file-error').css('display', 'none');
		
		// Типы документов которые можно загружать
		var format = ["pdf", "jpg", "jpeg", "png", "doc", "docx", "odt", "xls", "xlsx", "ods", "odt", "ods"];
		var parts = $(this).val()
		parts = parts.toLowerCase()
		parts = parts.split('.');
		parts = parts.pop();
		// Проверка на формат
		for (let i = 0; i < format.length; i++) {
			if (parts == format[i]) {
				error = false;
				break;
			}
			else {
				error = true;
			}
		}
		if (error == true) {
			this.value = "";
			$fileLabel.html('Прикрепить файл');
			$('.js-file-error').css('display', 'inline-block');
			$('.js-file-del').css('display', 'none');
		}
		else {
			if (this.files && this.files.length > 1)
				fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
			else if (e.target.value)
				fileName = e.target.value.split('\\').pop();
			if (fileName){
				$fileLabel.html(fileName);
			}
			else{
				$fileLabel.html(labelVal);
			}
			$('.js-file-del').css('display', 'inline-block');
		}
		Valid()
	})

	$(".js-file-del").on("click", function(){
		$('.js-file-input').val("");
		$('.js-file-name').html('Прикрепить файл');
		$('.js-file-del').css('display', 'none');
	})


	function Valid() {
		if (nameValid && phoneValid && organizationValid && emailValid && innValid) {
			button.attr("disabled", false)
		}
		else {
			button.attr("disabled", true)
		}
	};

    function ValidDataInput(input, error) {

        if (error == 0) {
            input.closest(".feedback__item").removeClass("error");
        }

        if (error == 1) {
            input.closest(".feedback__item").addClass("error");
            input.siblings(".feedback__error").html("Введите данные")

        }
        if (error == 2) {
            input.closest(".feedback__item").addClass("error");
            input.siblings(".feedback__error").html("Введите данные корректно");
		}
		Valid()
	};
	

	// Сброс значений при закрытии попапа
	$('.feedback-link').on('mfpClose', function(e) {
		for(let i = 0; $(".feedback__input").length > i; i++){
			$(".feedback__input").eq(i).val("");
		}
		$(".feedback__item").removeClass("error");
		$('.js-file-error').css('display', 'none');
		$(".js-file-input").val("");
		$(".js-file-name").html('Прикрепить файл');
		button.attr("disabled", true)

		emailValid = true;
		phoneValid = false;
		innValid = false;
		organizationValid = false;
		nameValid = false;
	});

    // autosize($('.js-data-input__input'))

});









$(document).ready(function () {






    // Приведение цен к правильному виду при загрузке страницы ( 0 => 0.00 )
    var priceArr = [
        ".js-total-sum-nonds", 
        ".js-total-nds", 
        ".js-total-sum", 
        ".js-price-sum", 
        ".js-price-one",
        ".table__content_price",
        ".js-card-basket__total-price",
        ".js-card-basket__price",
        ".price-invoice__price",
        ".price-invoice__total-price"
    ]
    for(let i = 0; priceArr.length > i; i++){
        if($(priceArr[i]).length != 0){
            CorrectionPrice(priceArr[i])
        }
    }

    function CorrectionPrice(elem){
        $(elem).each(function(){
            var value = +$(this).html();
            var newValue = value.toLocaleString("ru-RU", { minimumFractionDigits: 2 }).replace(',', '.')
            $(this).html(newValue)  
        })
    }


    // Проверка на наличие цены. Если она равна 0 или приходит текст, то вместо цены высвечивается надпись "по запросу"
    var priceArrValid = [
        ".table__content_price",
        ".search-dropdown__price",
        ".js-price-sum",
        ".price-invoice__price"
    ]
    for(let i = 0; priceArrValid.length > i; i++){
        
        if($(priceArrValid[i]).length != 0 ){
            $(priceArrValid[i]).removeClass("no-price")
            noPrice(priceArrValid[i])
        }
    }
    function noPrice(elem){
        $(elem).each(function(){
            if(+$(this).html() == 0){
                
                $(this).html("По запросу");
                $(this).addClass("no-price")
            }
        })
    }


})




$(document).ready(function () {
    var up = $(".js-goods-counter-up");
    var number = $(".js-goods-counter-number");
    var down = $(".js-goods-counter-down");
    var clear = $(".js-goods-counter-clear");
    var minValue = 0;
    var maxlength = 5;


    


	function validValueProduct(numberVal) {
        maxValueThis = numberVal.closest(".js-parent-counter").find(".js-goods-counter-max").html()
        if(numberVal.val() <= minValue ){
            numberVal.val("")
        }
        else if(numberVal.val().length > maxlength){
            numberVal.val(numberVal.val().slice(0,-1))
        }

        if(numberVal.val() <= 0){
            numberVal.siblings(".js-goods-counter-down").addClass("disable")
        }
        else if(numberVal.val() > 0){
            numberVal.siblings(".js-goods-counter-down").removeClass("disable")
        }

        if(numberVal.val() > 0){
            numberVal.closest(".js-parent-counter").addClass("active")
        }
        else{
            numberVal.closest(".js-parent-counter").removeClass("active")
        }


        if(numberVal.val() > +maxValueThis){
            numberVal.closest(".js-parent-counter").addClass("max-value")
        }
        else{
            numberVal.closest(".js-parent-counter").removeClass("max-value")
        }
    };
    

    $(number).on('input', function(){
        $(this).val($(this).val().replace(/[A-Za-zА-Яа-яЁё]/));
        // $(this).val($(this).val().replace(/^,*?((\d+\,?|\,)\d{0,2}),*|,+/, '$1'))
        // $(this).val($(this).val().replace(/^0*(?!\,|$)/, ''))
        // $(this).val($(this).val().replace(/^\,$|^(\,)/, '0$1'))
        console.log($(this).val())
        // if(isInteger($(this).val())){
        //     console.log($(this).val().isInteger)
        //     console.log($(this).val())
        // }
        validValueProduct($(this))
    })


    $(number).on('change', function(){
        if($(this).val() == "" ){
            $(this).val("")
        }
    })


    $(up).on("click", function(){
        var numThis = $(this).siblings(".js-goods-counter-number");
        numThis.val(+numThis.val()+1)
   
        validValueProduct(numThis)
    })


    $(down).on("click", function(){
        var numThis = $(this).siblings(".js-goods-counter-number");

        if(numThis.val() > 0){
            numThis.val(+numThis.val()-1)
        }
    
        validValueProduct(numThis)
    })

    $(clear).on("click", function(){
        var numThis = $(this).siblings(".js-goods-counter-number");
        numThis.val(minValue)
  
        validValueProduct(numThis)
    })
    
    
});
$(document).ready(function () {
    $(".js-basket-add").on("click", function(){
        if($(".card-basket__add-basket-inner").length != 0){
            var cardPage = $(".card-basket__add-basket-inner")
            addInBasketFromElement(cardPage)
        }
        UpdateBasket() 
        $(".js-goods-counter-number").each(function(){
            $(this).closest(".js-parent-counter").removeClass("active");
            $(this).closest(".js-parent-counter").removeClass("max-value");
            $(this).val(0);
        })
    })
    
})
function UpdateBasket(){
    $("#basket-small").load("/ #basket-small")
}

$(document).ready(function () {
    $('.feedback-link').magnificPopup({
        type: 'inline',
        preloader: false
    });


    $(".js-data-input-name").on("input", function () {
        var s = $(this).val();
        s = s.replace(/[^a-zA-Zа-яА-ЯёЁ\s\-]/gi, "");
        $(this).val(s);
    });



    $(".js-data-input-inn").on("input", function () {
        var s = $(this).val();
        s = s.replace(/[^0-9]/gi, ""),
            $(this).val(s);
    });

    $(".js-data-input-phone").inputmask({
        'mask': '+7 (999) 999-99-99',
        showMaskOnHover: false,
        onBeforePaste: function (pastedValue, opts) {
          var newValue = pastedValue.replace(/[^0-9]/ig, '');
          newValue = newValue.substring(newValue.length - 10);
          return newValue;
        },
      });



	$(".js-data-input-email").on("input", function () {
		var pattern = /^[a-z0-9_-]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/i;
		if ($(this).val() != '') {
			if ($(this).val().search(pattern) == 0) {
				nameEmail = false;
			}
			else {
				nameEmail = true;
			}
		}
		else if ($(this).val() == '') {
			nameEmail = false;
		}
		// Valid();
	});

    var validInput = true;
    $("#form__inner").submit(function () {
        validInput = true;
        var patternEmail = /^[a-z0-9_-]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/i;
        var email = $(this).find(".js-data-input-email"),
            phone = $(this).find(".js-data-input-phone"),
            inn = $(this).find(".js-data-input-inn"),
            organization = $(this).find(".js-data-input-organization"),
            name = $(this).find(".js-data-input-name"),
            error = 0;
        // 1 ошибка длины
        // 2 ошибка валидации

        if (name.val().length > 0) {
            error = 0;
            ValidDataInput(name, error)
        }
        else {
            error = 1
            ValidDataInput(name, error)
        }

        if (organization.val().length > 0) {
            error = 0;
            ValidDataInput(organization, error)
        }
        else {
            error = 1
            ValidDataInput(organization, error)
        }

        if (phone.val().replace(/[^0-9]/ig, '').length == 11) {
            error = 0;
            ValidDataInput(phone, error)
        }
        else if (phone.inputmask("unmaskedvalue").length == 0) {
            error = 1
            ValidDataInput(phone, error)
        }
        else {
            error = 2
            ValidDataInput(phone, error)
        }


        if (inn.val().length >= 10 && inn.val().length <= 12 ) {
            error = 0;
            ValidDataInput(inn, error)

        }
        else if (inn.val().length == 0) {
            error = 1
            ValidDataInput(inn, error)
        }
        else {
            error = 2
            ValidDataInput(inn, error)
        }

        if (email.val() != '') {
            if(email.val().search(patternEmail) == 0){
                error = 0;
                ValidDataInput(email, error)
            }
            else{
                error = 2
                ValidDataInput(email, error)
            }
        }
        else if (email.val().length == 0) {
            error = 1
            ValidDataInput(email, error)
        }
        
        if (validInput) {
            sendOrder($(this));
            // return true;
            
        }
        else {
            return false;
        }

    });



    function ValidDataInput(input, error) {

        if (error == 0) {
            input.closest(".data-input__item").removeClass("error");
        }

        if (error == 1) {
            input.closest(".data-input__item").addClass("error");
            input.siblings(".data-input__error").html("Введите данные");
            validInput = false;

        }
        if (error == 2) {
            input.closest(".data-input__item").addClass("error");
            input.siblings(".data-input__error").html("Введите данные корректно");
            validInput = false;
        }
    };


    autosize($('.js-data-input__input'))

});





$(document).ready(function () {

    var priceOne = $(".js-card-basket__price"),
        priceTotal = $(".js-card-basket__total-price"),
        valueSelect = $(".js-goods-counter-number");
        notPhoto = false;

    function validValueProduct() {
        if(+priceOne.attr("data") != 0 && +priceOne.attr("data")){
            var e = +priceOne.attr("data") * +valueSelect.val()
            priceTotal.html(e.toLocaleString("ru-RU", { minimumFractionDigits: 2 }).replace(',', '.'))
            priceOne.removeClass("no-price")
            priceTotal.removeClass("no-price")
        }

        else{
            priceOne.html("По запросу")
            priceOne.addClass("no-price")
            priceTotal.html("По запросу")
            priceTotal.addClass("no-price")
        }
        
        // if(e == undefined || e == NaN){
        //     e = 0;
        //     debugger
        // }
        
        
    }

    $(".js-goods-counter-number").on("input", function () {
        validValueProduct()
    })
    $(".js-goods-counter-down").on("click", function () {
        validValueProduct()
    })
    $(".js-goods-counter-up").on("click", function () {
        validValueProduct()
    })
    $(".card-basket__add-basket").on("click", function(){
        valueSelect.val("0")
    })
    validValueProduct()
});



$(document).ready(function () {
    var index;
    var childBig;
    $(".js-small-inner").on("click", function () {
        index = 1;
        for (var i = 0; $(".js-small-inner").length > i; i++) {
            $(".js-small-inner").eq(i).removeClass("active")
            $(".js-big-inner").eq(i).removeClass("active")
        }
        $(this).addClass("active")
        index = $(this).find(".card-photo__small").attr("indexSmall")
        console.log(index)

        childBig = $(".card-photo__big[indexBig='" + index + "']")
        childBig.closest(".js-big-inner").addClass("active")
        
    })

});





// https://www.tune-it.ru/web/leksa/blog/-/blogs/1932103
$(document).ready(function () {
    $(".js-big-inner .js-card-photo__big").each(function (key, item) {
        $(item).on("error", function () {
            showDefaultImage(this);
        }).attr('src', $(item).attr('src'));
    });
});

function showDefaultImage(img) {
    $(img).attr('src', './assets/images/nophoto/text.png');
    $(img).off("error");
    notPhoto = true;
    if(notPhoto && $(".js-small-inner").length == 1){
        $(".js-small-inner").addClass("no-photo")
    }
    
}


$(document).ready(function () {
	$('.js-open-certificate').magnificPopup({
		type: 'inline',
		preloader: false,
    });
});

$(document).ready(function () {
	$(".js-open-certificate").on("click", function(){
        var srcImg = $(this).attr("dataImg")
        console.log(srcImg)
        $(".js-popup-certificate-img").attr("src", srcImg)
    })
});
$(document).ready(function () {

    $(".js-price-one").each(function(){
        PriceSum($(this))
    })

    $(".js-goods-counter-number").each(function(){
        if($(this).val() > 0){
            $(this).closest(".js-parent-counter").addClass("active")
        }
        
    })

    function PriceSum(priceOne) {
        var parentThis = priceOne.closest(".js-parent-counter")
        var sum = parentThis.find(".js-price-sum")
        var valueSelect = parentThis.find(".js-goods-counter-number")
        
        var e = +priceOne.attr("data") * valueSelect.val();
        console.log(e.toFixed(5));
        if(e.toFixed(0) == 0){
            sum.html("По запросу")
        }
        else{
            sum.html(e.toFixed(2).toLocaleString())
            sum.attr("data", e)
        }
        
    }

    // Значения для бека 
    function ValueBack(elem) {
        var parentThis = elem.closest(".js-parent-counter")
        var id = parentThis.find(".table__content_name").attr("data-id")
        var newCount = elem.val();   
        if($('.basket').length == true){
            UpdateBasket()
            if(elem.hasClass("js-stroke-delete") == false){
                basketEdit(id, newCount);
            }
        }
    }

    function TotalSum() {
        var sumTotal = 0;
        var ndsValue = 0;
        var allPrice = 0;
        for(var i = 0; $(".js-price-sum").length > i; i++){
            sumTotal = sumTotal + +$(".js-price-sum").eq(i).attr("data")
        }
        
        $(".js-total-sum-nonds").attr("data", sumTotal);
        $(".js-total-sum-nonds").html( sumTotal.toFixed(2).toLocaleString());

        $(".js-total-sum").attr("data", sumTotal);
        $(".js-total-sum").html( sumTotal.toFixed(2).toLocaleString());

        ndsValue = (sumTotal / 100) * +$(".js-total-nds").attr("dataNDS")

        $(".js-total-nds").attr("data", ndsValue);
        $(".js-total-nds").html( ndsValue.toFixed(2).toLocaleString());

        allPrice = sumTotal + ndsValue

        $(".js-total-sum").attr("data", allPrice);
        $(".js-total-sum").html( allPrice.toFixed(2).toLocaleString());

        
    }


    $(".js-goods-counter-number").on("input", function () {
        var parentThis = $(this).closest(".js-parent-counter")
        var priceOne = parentThis.find(".js-price-one")
        PriceSum(priceOne)
        TotalSum()

        ValueBack($(this)) 
    })
    $(".js-goods-counter-down").on("click", function () {
        var parentThis = $(this).closest(".js-parent-counter")
        var priceOne = parentThis.find(".js-price-one")
        PriceSum(priceOne)
        TotalSum()

        var valB = parentThis.find(".js-goods-counter-number")
        ValueBack(valB) 
    })
    $(".js-goods-counter-up").on("click", function () {
        var parentThis = $(this).closest(".js-parent-counter")
        var priceOne = parentThis.find(".js-price-one")
        PriceSum(priceOne)
        TotalSum()

        var valB = parentThis.find(".js-goods-counter-number")
        ValueBack(valB) 
    })

    $(".js-stroke-delete").on("click", function () {
        strokeBasketDelete($(this));
        $(this).closest(".js-parent-counter").remove()
        TotalSum()

        ValueBack($(this)) 
    })
    
})




$(document).ready(function () {
    $(".js-category-item-img").each(function (key, item) {
        $(item).on("error", function () {
            showDefaultImageSubCategory(this);
        }).attr('src', $(item).attr('src'));
    });
});

function showDefaultImageSubCategory(img) {
    $(img).attr('src', '/local/templates/main/assets/images/nophoto/logo.png');
    $(img).off("error");
}
$(document).ready(function () {
    var yCoordinate = +$("#YMapsID").attr("y-coordinate")
    var xCoordinate = +$("#YMapsID").attr("x-coordinate")

    ymaps.ready(function () {
        var myMap = new ymaps.Map("YMapsID", {
          center: [yCoordinate, xCoordinate],
          zoom: 16,
          controls: []
        });
      });
});